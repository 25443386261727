import * as React from 'react';
import { FC, useState } from 'react';
import {
    Edit,
    Labeled,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextInput,
    useEditContext,
    useGetIdentity,
    useGetList,
    useNotify,
    usePermissions,
    useUpdate,
} from 'react-admin';
import { AccordionSection } from '@react-admin/ra-form-layout';
import { useFormContext } from 'react-hook-form';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    Alert,
    Box,
    Button,
    Chip,
    CircularProgress,
    Step,
    StepConnector,
    StepLabel,
    Stepper,
    Tab,
    Tabs,
    Tooltip,
    Typography,
    stepConnectorClasses,
    styled,
} from '@mui/material';
import { Help } from '@mui/icons-material';
import ForwardIcon from '@mui/icons-material/Forward';
import SaveIcon from '@mui/icons-material/Save';
import { Identifier } from 'ra-core';
import { BackButton } from '../../../components/ui/button/BackButton';
import { IodToolbar } from '../../../components/ui/layout/IodToolbar';
import { useSidebarWidth } from '../../../hooks/useSidebarWidth';
import { ErrorResponse } from '../../../types/errorResponse';
import { Organization } from '../../organizations/types/organization';
import { CommitAndDiffContainer } from '../../workflow/components/CommitsAndDiffContainer';
import { LineComment } from '../../workflow/components/DiffViewer';
import { IodDiffViewer } from '../../workflow/components/IodDiffViewer';
import { ContentMetadata } from '../../workflow/types/diffInfo';
import {
    LineDiscussion,
    LineDiscussionAdd,
    LineDiscussionUpdate,
} from '../../workflow/types/discussion/content-discussion/lineDiscussion';
import { ContentNode } from '../types/contentNode';
import OrganizationArrayInput from './OrganizationArrayInput';
import { ParagraphMarkdownInput } from './ParagraphMarkdownInput';
import PhaseSelectInput from './PhaseSelectInput';
import { S3FilesInput } from './S3FilesInput';

const ColoredConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

export const ContentEditPage = () => {
    // document_id, content_path を準備
    const { document_id } = useParams();
    const location = useLocation();
    const match = matchPath('edit/documents/:document_id/edit/contents/*', location.pathname);
    const content_path = match?.params['*'] || '';

    const sidebarWidth = useSidebarWidth();

    // 現在アクティブなステップの状態管理
    const [activeStep, setActiveStep] = useState(0);

    // 編集後データの状態管理
    const [editData, setEditData] = useState<ContentNode>();
    const [lineDiscussions, setLineDiscussions] = useState<LineDiscussion[]>([]);

    const stepsLabel = ['データの編集', '修正理由等の入力・編集内容確認'];

    if (!document_id) {
        throw new Error('document id is empty');
    }

    return (
        <Edit
            resource={`edit/documents/${document_id}/contents`}
            id={content_path}
            redirect={`edit/documents/${document_id}/contents`} // リダイレクト先は要調整
            title="目次（最新修正案）の編集" // デフォルトだとsuffixにIDが付与されるため
        >
            <Box sx={{ width: `calc(100vw - ${sidebarWidth + 18}px)` }}>
                <Stepper activeStep={activeStep} alternativeLabel sx={{ mt: 2 }} connector={<ColoredConnector />}>
                    {stepsLabel.map((label, index) => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            editData={editData}
                            setEditData={setEditData}
                            lineDiscussions={lineDiscussions}
                        />
                    }
                    // sx={{
                    //     display: 'flex',
                    //     alignItems: 'center',
                    //     // height: '100%' // 必要に応じて高さを調整してください
                    // }}
                >
                    {/* Step */}
                    {activeStep === 0 && <ContentEditStep />}
                    {activeStep === 1 && (
                        <ContentConfirmStep
                            documentId={Number(document_id)}
                            editData={editData}
                            lineDiscussions={lineDiscussions}
                            setLineDiscussions={setLineDiscussions}
                        />
                    )}
                </SimpleForm>
            </Box>
        </Edit>
    );
};

interface CustomToolbarProps {
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    editData: ContentNode | undefined;
    setEditData: React.Dispatch<React.SetStateAction<ContentNode | undefined>>;
    lineDiscussions: LineDiscussion[];
}

const CustomToolbar = (props: CustomToolbarProps) => {
    const { activeStep, setActiveStep, setEditData, editData, lineDiscussions } = props;

    // 確認ボタン関連
    const { getValues } = useFormContext<ContentNode>();

    // 保存ボタン関連
    const [update] = useUpdate<ContentNode>();
    const navigate = useNavigate();
    const notify = useNotify();
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const handleConfirm = (e: any) => {
        // 送信イベントは塞ぐ
        e.preventDefault();
        // 現在のフォーム内容を保持
        const formData = getValues();
        setEditData(formData);

        // console.log(JSON.stringify(formData));

        setActiveStep(1); // ステップを進める
    };

    const handleSave = async () => {
        setButtonDisabled(true);

        const discussions: Array<LineDiscussionAdd> = lineDiscussions.map((discussion) => {
            return {
                line_no: discussion.line_no,
                classification: discussion.classification,
                reason: discussion.reason,
                comment: discussion.comments[0].body,
            };
        });

        await update(
            `documents/${editData?.document_id}/contents`,
            {
                id: editData?.content_path,
                data: {
                    ...editData,
                    line_discussions: discussions,
                },
            },
            {
                onSuccess: () => {
                    notify(<Alert severity="info">保存が完了しました。まとめて承認依頼ができます。</Alert>);

                    setTimeout(() => {
                        // 3秒待ってからリダイレクト
                        navigate(`/edits`);
                        setButtonDisabled(false);
                    }, 3000);
                },
                onError: (error: ErrorResponse | any) => {
                    notify(
                        <Alert severity="error">
                            保存に失敗しました。
                            <br />
                            {error.status && error.message}
                        </Alert>,
                    );
                    setButtonDisabled(false);
                },
            },
        );
    };

    const handleBackButton = () => {
        if (activeStep >= 1) {
            setActiveStep(activeStep - 1);
        }
    };

    return (
        <IodToolbar>
            {activeStep === 0 && <BackButton />}
            {activeStep === 0 && (
                <SaveButton
                    variant="contained"
                    color="primary"
                    icon={<ForwardIcon />}
                    onClick={handleConfirm}
                    label="確認"
                />
            )}
            {activeStep === 1 && (
                <>
                    <BackButton onClick={handleBackButton} />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        disabled={isButtonDisabled || lineDiscussions.length == 0}
                        onClick={handleSave}
                    >
                        保存
                    </Button>
                </>
            )}
        </IodToolbar>
    );
};

const ContentEditStep: FC = () => {
    // タブの状態管理
    const [activeTab, setActiveTabIndex] = useState(1);
    // 権限
    const { isLoading: isLoadingPermissions, permissions } = usePermissions();

    // データ関連
    const { record, isLoading } = useEditContext<ContentNode>();

    // console.log(`useEditContext: ${JSON.stringify(record)}`)

    if (isLoading || isLoadingPermissions || !record) {
        return <CircularProgress />;
    }

    // メタデータの編集専用の権限はまだないため、ドキュメントの編集権限で代用する
    const canEditMetadata = permissions.documents.includes('update');

    const content_type = record.content_type;
    const isEditingHeading = content_type === 'heading';
    const hasSavePolicy = isEditingHeading
        ? permissions.headings.includes('update')
        : permissions.paragraphs.includes('update');
    const hasDeletePolicy = isEditingHeading
        ? permissions.headings.includes('delete')
        : permissions.paragraphs.includes('delete');
    const hasUpdateHeadingPolicy = permissions.headings.includes('update');

    const handleTabChange = (event: React.ChangeEvent<{}>, selectedTabIndex: number) => {
        setActiveTabIndex(selectedTabIndex); // 選択されたタブINDEXを保持
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="編集履歴" disabled={isEditingHeading} />
                    <Tab label="編集" />
                </Tabs>
            </Box>
            <Box sx={{ width: '100%' }}>
                {/* タブ index:0 */}
                <CustomTabPanel activeTab={activeTab} tabPosition={0}>
                    <CommitAndDiffContainer
                        documentId={record?.document_id || 0}
                        contentPath={record?.content_path || ''}
                    />
                </CustomTabPanel>
                {/* タブ index:1 */}
                <CustomTabPanel activeTab={activeTab} tabPosition={1}>
                    <Box
                        style={{
                            maxHeight: 'calc(100vh - 350px)', // 高さ調整
                            overflow: 'auto',
                            width: '100%',
                        }}
                    >
                        <NumberInput source="id" name="id" label="コンテンツノードID" sx={{ display: 'none' }} />
                        <NumberInput
                            source="document_id"
                            name="document_id"
                            label="ドキュメントID"
                            sx={{ display: 'none' }}
                        />
                        <NumberInput
                            source="content_id"
                            name="content_id"
                            label="コンテンツID"
                            sx={{ display: 'none' }}
                        />

                        {!hasUpdateHeadingPolicy && isEditingHeading ? (
                            <Alert severity="info" sx={{ mb: 2 }}>
                                見出しを編集する権限がありません。
                            </Alert>
                        ) : (
                            // 編集できる状態のときは常に警告を表示する
                            <Alert severity="warning" sx={{ mb: 2 }}>
                                別画面に移動すると未保存のデータは消去されます。
                            </Alert>
                        )}

                        <Labeled label="コンテンツタイプ" sx={{ mb: 2 }}>
                            <Chip
                                label={isEditingHeading ? '見出し' : 'パラグラフ'}
                                variant="outlined"
                                sx={{ mt: 0.5 }}
                            />
                        </Labeled>

                        {isEditingHeading ? (
                            <TextInput
                                name="body"
                                source="body"
                                label="内容"
                                maxRows={3}
                                multiline
                                fullWidth
                                inputProps={{ maxLength: 100 }}
                            />
                        ) : (
                            <>
                                <ParagraphMarkdownInput
                                // name="body"
                                // source="body"
                                // label="編集エリア"
                                // hideModeSwitch={true} // 202401 第3回ユーザテスト向けに、markdownのみに修正
                                // handleImagePaste={onImagePaste}
                                />
                                <PhaseSelectInput source="phase" disabled={!canEditMetadata} />

                                {/* 組織 */}
                                <OrganizationArrayInput type="responsible_organization" disabled={!canEditMetadata} />
                                <OrganizationArrayInput
                                    type="co_responsible_organization"
                                    disabled={!canEditMetadata}
                                />

                                <AccordionSection label="画像中の文言" fullWidth sx={{ mt: 2, mb: 2 }}>
                                    <TextInput
                                        name="embedded_text"
                                        source="embedded_text"
                                        label="画像中の文言"
                                        maxRows={10}
                                        multiline
                                        fullWidth
                                        inputProps={{ maxLength: 1000 }}
                                    />
                                </AccordionSection>
                                <TextInput
                                    name="note"
                                    source="note"
                                    label="備考"
                                    placeholder="一般には公開されない項目です。編集作業中の連絡にご利用ください。"
                                    maxRows={3}
                                    multiline
                                    fullWidth
                                    inputProps={{ maxLength: 100 }}
                                />
                                <S3FilesInput
                                    name="files"
                                    documentId={record.document_id}
                                    contentPath={record.content_path}
                                    source="files"
                                    label={
                                        <>
                                            関連ファイル
                                            <Tooltip
                                                placement="top"
                                                title="本文中に含まれる表の元データ（xlsxやdocxファイル等）を添付してください"
                                            >
                                                <Help sx={{ position: 'relative', top: '5px' }} />
                                            </Tooltip>
                                            <Typography
                                                sx={{
                                                    // FileInputのhelperTextのスタイルを再現
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontSize: '0.75rem',
                                                    letterSpacing: '0.03em',
                                                }}
                                            >
                                                ※ここにアップロードしたファイルは一般には公開されませんが、編集者（都各局等・関係機関）であればダウンロードできます。
                                                <br />
                                                ※個人情報を含むファイルや機密文書をアップロードしないようにしてください。
                                            </Typography>
                                        </>
                                    }
                                />
                            </>
                        )}
                    </Box>
                </CustomTabPanel>
            </Box>
            {/* タブ index:0 */}
            {/*<Snackbar*/}
            {/*    open={open}*/}
            {/*    // autoHideDuration={6000}*/}
            {/*    onClose={handleClose}*/}
            {/*    message={ocrText}*/}
            {/*    action={action}*/}
            {/*    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}*/}
            {/*/>*/}
        </Box>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    tabPosition: number;
    activeTab: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, activeTab, tabPosition } = props;

    return (
        <Box
            role="tabpanel"
            hidden={activeTab !== tabPosition}
            id={`simple-tabpanel-${tabPosition}`}
            aria-labelledby={`simple-tab-${tabPosition}`}
        >
            {/* アクティブなタブに対応するコンテンツだけを表示する */}
            {activeTab === tabPosition && <Box sx={{ p: 3 }}>{children}</Box>}
        </Box>
    );
};

interface ContentConfirmProps {
    documentId: number;
    editData: ContentNode | undefined;
    lineDiscussions: LineDiscussion[]; // 新たに追加
    setLineDiscussions: React.Dispatch<React.SetStateAction<LineDiscussion[]>>;
}

type OrganizationDict = Record<Identifier, Organization>;

const ContentConfirmStep: FC<ContentConfirmProps> = ({
    editData: newContent, // 未保存の編集後データ
    documentId,
    lineDiscussions,
    setLineDiscussions,
}) => {
    // データ関連
    const { record: oldContent } = useEditContext<ContentNode>();
    const { data: userData, isLoading: isLoadingId } = useGetIdentity();
    const { data: allOrganizations } = useGetList<Organization>('organizations');

    if (isLoadingId || !userData || !allOrganizations) {
        return <CircularProgress />;
    }

    // 組織IDをキーにした辞書を用意
    const organizationForEachId: OrganizationDict = allOrganizations.reduce(
        (dict: OrganizationDict, org: Organization) => {
            dict[org.id] = org;
            return dict;
        },
        {}, // 初期値
    );

    const onComment = (comment: LineComment) => {
        const dateTimeFormat = new Intl.DateTimeFormat(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            // fractionalSecondDigits: 3,
        });
        const nowStr = dateTimeFormat.format(new Date());
        const maxId = Math.max(0, ...lineDiscussions.map((discussion) => Number(discussion.id)));

        setLineDiscussions([
            ...lineDiscussions,
            {
                id: maxId + 1,
                classification: comment.classification,
                comments: [
                    {
                        id: 1,
                        body: comment.comment || '',
                        author: {
                            id: userData.id,
                            name: userData.fullName || '',
                            email: '',
                            role_id: 0,
                            status: '',
                            affiliation: userData.affiliation || '',
                            created_at: nowStr,
                            updated_at: nowStr,
                        },
                        created_at: nowStr,
                        updated_at: nowStr,
                    },
                ],
                revision_id: 1, // TODO 不要なはず＆revisionなので別途整理
                created_at: nowStr,
                line_no: comment.lineNo,
                content_id: oldContent?.content_id || 1, // TODO コンテンツの新規作成時は指定不要であるべき
                reason: comment.reason,
                resolved: false,
                updated_at: nowStr,
            },
        ]);
    };

    const onDeleteDiscussion = (commentId: Identifier) => {
        const updatedLineDiscussions = lineDiscussions.filter((discussion) => {
            return discussion.id !== commentId;
        });
        setLineDiscussions(updatedLineDiscussions);
    };

    const onEditDiscussion = (discussionId: Identifier, discussionUpdate: LineDiscussionUpdate) => {
        const updatedLineDiscussions = lineDiscussions.map((discussion) => {
            if (discussion.id !== discussionId) {
                return discussion;
            }

            return {
                ...discussion,
                line_no: discussionUpdate.line_no !== undefined ? discussionUpdate.line_no : discussion.line_no,
                classification:
                    discussionUpdate.classification !== undefined
                        ? discussionUpdate.classification
                        : discussion.classification,
                reason: discussionUpdate.reason !== undefined ? discussionUpdate.reason : discussion.reason,
                comments:
                    discussionUpdate.comment !== undefined
                        ? [
                              {
                                  ...discussion.comments[0],
                                  body: discussionUpdate.comment,
                              },
                          ]
                        : [discussion.comments[0]],
                resolved: discussionUpdate.resolved !== undefined ? discussionUpdate.resolved : discussion.resolved,
            };
        });
        setLineDiscussions(updatedLineDiscussions);
    };

    const extractOrganizationByIds = (organizationIds: number[]): Array<Organization> => {
        if (!organizationIds) {
            return [];
        }

        const organizations = organizationIds.map((organizationId: number) => {
            const org = organizationForEachId[organizationId];
            if (!org) {
                // 一致する組織が見つからなかった場合、エラーとする
                throw new Error(`No matching organization ID: ${organizationId} found in all organizations`);
            }

            return org;
        });
        return organizations;
    };

    // メタデータ情報を用意
    const newMetadata: ContentMetadata = {
        phase: newContent?.phase || null,
        responsible_organizations: extractOrganizationByIds(newContent?.responsible_organization_ids || []),
        co_responsible_organizations: extractOrganizationByIds(newContent?.co_responsible_organization_ids || []),
        embedded_text: newContent?.embedded_text || '',
        note: newContent?.note || '',
        files: newContent?.files || [],
    };
    const oldMetadata: ContentMetadata = {
        phase: oldContent?.phase || null,
        responsible_organizations: extractOrganizationByIds(oldContent?.responsible_organization_ids || []),
        co_responsible_organizations: extractOrganizationByIds(oldContent?.co_responsible_organization_ids || []),
        embedded_text: oldContent?.embedded_text || '',
        note: oldContent?.note || '',
        files: oldContent?.files || [],
    };

    return (
        <Box sx={{ width: '100%' }}>
            {lineDiscussions.length === 0 && (
                <Alert severity="info" sx={{ mb: 2 }}>
                    保存するには「修正理由等」の入力が必須です。
                </Alert>
            )}
            <IodDiffViewer
                newContent={newContent?.body ?? ''}
                oldContent={oldContent?.body ?? ''}
                newPath={newContent?.content_path ?? ''}
                oldPath={oldContent?.content_path ?? ''}
                newMetadata={newMetadata}
                oldMetadata={oldMetadata}
                document_id={documentId}
                // コメント表示に関する設定は使用しない
                discussions={lineDiscussions}
                showHistory={true}
                showMetadataDiff={true}
                onComment={onComment}
                onEditDiscussion={onEditDiscussion}
                onDeleteDiscussion={onDeleteDiscussion}
                onReply={() => {}}
                onEditReply={() => {}}
                onDeleteReply={() => {}}
                canComment={true}
                canReply={false}
            />
        </Box>
    );
};
