import { useEffect, useState } from 'react';
import { RecordContextProvider, useListController, useRecordContext } from 'react-admin';
import { Box, CircularProgress, Stack } from '@mui/material';
import IodMarkdownField from '../../components/ui/fields/IodMarkdownField';
import { FavoriteButton } from '../favorites/components/FavoriteButton';
import { ListHeadingsField } from './components/ListHeadingsField';
import OrganizationsField from './components/OrganizationsField';
import { ParagraphEditButton } from './components/ParagraphEditButton';
import { PhaseField } from './components/PhaseField';
import { ParagraphBodyHighlight } from './paragraphBodyHighlight';
import { Paragraph } from './types/paragraph';

interface ParagraphDetailProps {
    version?: string;
}
export const ParagraphDetail = (props: ParagraphDetailProps) => {
    const record = useRecordContext<Paragraph>();
    const [modifiedParagraph, setModifiedParagraph] = useState<Paragraph>();

    // useListControllerを用いて検索キーワードを用意する
    // see: https://marmelab.com/react-admin/useListController.html
    const { filterValues, isLoading } = useListController();

    useEffect(() => {
        if (record && filterValues) {
            const keywords = ParagraphBodyHighlight.normalizeKeyword(filterValues.q);
            const highlightedBody = ParagraphBodyHighlight.highlightBody(record.body, keywords);

            // 加工後のテキストを反映する
            setModifiedParagraph({ ...record, body: highlightedBody });
        }
    }, [record, filterValues]);

    if (isLoading || !modifiedParagraph) {
        return <CircularProgress />;
    }

    return (
        // 変更後のコンテキストを与える
        <RecordContextProvider value={modifiedParagraph}>
            <Box
                sx={{
                    marginLeft: '5px',
                    marginRight: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    mb: 2,
                }}
            >
                <Stack direction={'row'} alignItems={'center'} gap={[0, 2]} sx={{ mb: 2.5 }}>
                    <ListHeadingsField current_paragraph={record} show_parent_heading_only />
                    <Box>
                        <FavoriteButton content_id={record.content_id} />
                    </Box>
                    {props.version === 'pending' && (
                        <Box>
                            <ParagraphEditButton paragraph={record} />
                        </Box>
                    )}
                </Stack>

                <PhaseField></PhaseField>
                <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
                    <OrganizationsField type="responsible_organization" />
                    <OrganizationsField type="co_responsible_organization" />
                </Stack>

                <IodMarkdownField source="body" className="font-size-changeable" />
            </Box>
        </RecordContextProvider>
    );
};
