import React, { useEffect, useState } from 'react';
import {
    DateField,
    EditBase,
    Labeled,
    SaveButton,
    ShowBase,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    Title,
    Toolbar,
    TopToolbar,
    usePermissions,
    useRecordContext,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { BackButton } from '../../../../../components/ui/button/BackButton';
import { UserField } from '../../../../../components/ui/fields/UserField';
import { UsersField } from '../../../../../components/ui/fields/UsersField';
import { IodToolbar } from '../../../../../components/ui/layout/IodToolbar';
import { useSidebarWidth } from '../../../../../hooks/useSidebarWidth';
import { DiffsRequestComponent } from '../../../components/DiffsRequestComponent';
import { MergeRequestEditCard } from '../../../components/MergeRequestEditCard';
import { MergeRequestReviewCard } from '../../../components/MergeRequestReviewCard';
import { MergeRequestShowCard } from '../../../components/MergeRequestShowCard';
import { MergeRequestInfo } from '../../../types/mergeRequest';
import { MergeRequestActivityCard } from './MergeRequestActivityCard';
import { MergeRequestOperationButtons } from './MergeRequestOperationButtons';

const scrollBarWidth = 20;

export const MergeRequestDetail = () => {
    const navigate = useNavigate();
    // 権限制御
    const { isLoading: isLoadingPermissions, permissions } = usePermissions();

    // 権限がロードされた後、権限がない場合はホームにリダイレクト
    useEffect(() => {
        if (!isLoadingPermissions && !permissions.paragraphs.includes('update')) {
            navigate('/');
        }
    }, [isLoadingPermissions, permissions, navigate]);

    if (isLoadingPermissions) {
        return <CircularProgress />;
    }

    return (
        <EditBase>
            <Title title="確認・承認依頼詳細"></Title>

            <TopToolbar sx={{ justifyContent: 'flex-start' }}>
                <BackButton />
            </TopToolbar>

            {/* 候補：https://mui.com/material-ui/react-grid/ */}
            <MergeRequestInfoCard />

            <DiffsRequestComponentCard />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <MergeRequestReviewCard />
                </Grid>
                <Grid item xs={6}>
                    <MergeRequestActivityCard />
                </Grid>
            </Grid>
        </EditBase>
    );
};

const MergeRequestInfoCard = () => {
    const record = useRecordContext<MergeRequestInfo>();
    const [editMode, setEditMode] = useState(false);

    const handleEditMode = () => {
        setEditMode(true);
    };

    const handleCancelEditMode = () => {
        setEditMode(false);
    };

    if (!record || !record.id) {
        return <CircularProgress />;
    }

    if (editMode) {
        return (
            <MergeRequestEditCard
                merge_request_id={record.id}
                title="確認・承認依頼情報"
                sx={{ mb: 2, mt: 0 }}
                toolbar={
                    <IodToolbar>
                        <SaveButton
                            onClick={(e) => {
                                // 正確に制御するにはuseUpdateを使って更新のAPIコールのレスポンスを待って制御するべきだが必要十分と判断
                                setTimeout(() => {
                                    setEditMode(false);
                                }, 500);
                            }}
                        />
                    </IodToolbar>
                }
                action={
                    <Button
                        variant="text"
                        color="primary"
                        onClick={handleCancelEditMode}
                        sx={{ pl: 1.5, columnGap: 0.5 }}
                    >
                        <ArrowBack fontSize="small" />
                        編集をキャンセル
                    </Button>
                }
            />
        );
    } else {
        return (
            <MergeRequestShowCard
                merge_request_id={record.id}
                title="確認・承認依頼情報"
                sx={{ mb: 2, mt: 0 }}
                toolbar={
                    <Toolbar>
                        <MergeRequestOperationButtons allowedOptions={['withdraw', 'reopen']} />
                    </Toolbar>
                }
                // TODO 依頼本体の編集はいったん非表示にします（管理者もしくは承認者ぐらいなら編集できるポリシーをつくってそれで制御するのがよさげ）
                action={
                    <Button
                        variant="text"
                        color="primary"
                        onClick={handleEditMode}
                        sx={{ pl: 1.5, columnGap: 0.5, display: 'none' }}
                    >
                        <EditIcon fontSize="small" />
                        編集
                    </Button>
                }
            />
        );
    }
};

const DiffsRequestComponentCard = () => {
    const record = useRecordContext<MergeRequestInfo>();
    const sidebarWidth = useSidebarWidth();
    const navigate = useNavigate();

    if (!record || !record?.content) {
        return <CircularProgress />;
    }

    const handleEdit = async () => {
        // MEMO:当画面の時点で差分は必ず永続化されている想定（パラメータ渡し不要想定）
        // パラグラフ編集画面を開く
        navigate(
            `/edit/documents/${record.content?.document_id}/contents/${encodeURIComponent(
                record.content?.path || '',
            )}/show`,
        );
    };

    return (
        <Card sx={{ mb: 2 }}>
            <CardHeader title="編集内容" />

            {record.content?.content_id && (
                <>
                    <CardContent>
                        <ShowBase resource="edits" id={record.content.content_id}>
                            <SimpleShowLayout>
                                <TextField source="document_title" />
                                <UsersField source="updated_by" />
                                <UserField source="last_updated_by" />
                                <DateField source="updated_at" showTime={true} />
                                <DateField source="user_last_modified_at" showTime={true} />
                            </SimpleShowLayout>
                            {/*<Labeled label="更新者" sx={{ mb: 2.5 }}>*/}
                            {/*    <UsersField source="updated_by" />*/}
                            {/*</Labeled>*/}
                        </ShowBase>
                    </CardContent>
                    {/* 本当はDiffsRequestComponentもCardContentで囲みたいが内側が100%のwidthなので要調整 */}
                    <DiffsRequestComponent
                        content_ids={[record.content.content_id]}
                        showHistory={true}
                        canComment={false}
                        sx={{
                            width: `calc(100vw - ${sidebarWidth + scrollBarWidth + 16}px)`,
                        }}
                    />
                </>
            )}
            {/*</CardContent>*/}

            <IodToolbar>
                <CardActions>
                    {record.content?.deleted_at === null && (
                        <Button variant="contained" color="primary" onClick={handleEdit}>
                            <EditIcon fontSize="small" />
                            編集
                        </Button>
                    )}
                </CardActions>
            </IodToolbar>
        </Card>
    );
};
