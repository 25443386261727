import * as React from 'react';
import { ReactElement, useCallback, useEffect, useRef } from 'react';
import {
    Datagrid,
    ExportButton,
    FunctionField,
    Identifier,
    NumberField,
    ReferenceField,
    ShowBase,
    TextField,
    TopToolbar,
    WrapperField,
    useListContext,
    useListController,
    useResourceContext,
    useStore,
} from 'react-admin';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Drawer } from '@mui/material';
import { ApplyFontSize } from '../../components/functional/FontSizeProvider';
import { HierarchicalOrgContext } from '../../components/functional/HierarchicalOrgProvider';
import IodMarkdownField from '../../components/ui/fields/IodMarkdownField';
import { IodList } from '../../components/ui/layout/IodList';
import { useSidebarWidth } from '../../hooks/useSidebarWidth';
import { ContentPhaseType } from '../contents/types/contentPhaseType';
import { FavoriteButtonField } from '../favorites/components/FavoriteButtonField';
import { ParagraphShow } from './ParagraphShow';
import { InnerHeadingsField } from './components/InnerHeadingsField';
import { OrganizationsSearchResultsField } from './components/OrganizationsSearchResultsField';
import { ParagraphExpand } from './components/ParagraphExpand';
import { ParagraphFilterButton } from './components/ParagraphFilterButton';
import { editingParagraphFilters, snapshotParagraphFilters } from './components/ParagraphFilters';
import { paragraphCsvExporter } from './components/paragraphCsvExporter';
import rowStyle from './rowStyle';

// const tabs = [
//     { id: 'preface', name: 'まえがき' },
//     { id: 'prevention', name: '予防' },
//     { id: 'emergency', name: '応急' },
//     { id: 'restoration', name: '復旧' },
// ];

// const useGetTotals = (filterValues: any) => {
//     const { total: totalPreface } = useGetList('paragraphs', {
//         pagination: { perPage: 1, page: 1 },
//         sort: { field: 'id', order: 'ASC' },
//         filter: { ...filterValues, status: 'preface' },
//     });
//     const { total: totalPrevention } = useGetList('paragraphs', {
//         pagination: { perPage: 1, page: 1 },
//         sort: { field: 'id', order: 'ASC' },
//         filter: { ...filterValues, status: 'prevention' },
//     });
//     const { total: totalEmergency } = useGetList('paragraphs', {
//         pagination: { perPage: 1, page: 1 },
//         sort: { field: 'id', order: 'ASC' },
//         filter: { ...filterValues, status: 'emergency' },
//     });
//     const { total: totalRestoration } = useGetList('paragraphs', {
//         pagination: { perPage: 1, page: 1 },
//         sort: { field: 'id', order: 'ASC' },
//         filter: { ...filterValues, status: 'emergency' },
//     });
//
//     return {
//         preface: totalPreface,
//         prevention: totalPrevention,
//         emergency: totalEmergency,
//         restoration: totalRestoration,
//     };
// };

export interface ParagraphListProps {
    selectedRow?: Identifier;
    version?: string;
}

const DataGridComponent = (props: ParagraphListProps) => {
    const { selectedRow, version } = props;
    const resource = useResourceContext();
    const { page, perPage } = useListContext();

    // expandされているidのリストを保持するストア
    // react-admin useExpandAllの内部を解析して、keyを特定した。
    const [value, setValue] = useStore(`${resource}.datagrid.expanded`, []);

    // ページ遷移が発生したタイミングで初期化する
    useEffect(() => {
        setValue([]);
    }, [page, perPage, setValue]);

    return (
        <Datagrid
            rowClick={(a, b, c) => {
                return 'show';
            }}
            // rowClick="edit"
            // rowClick="expand"
            expand={<ParagraphExpand version={version} />}
            expandSingle={false}
            // https://trello.com/c/vnqxKuY3
            bulkActionButtons={false}
            onClick={(e: any) => {}}
            rowSx={rowStyle(selectedRow)}
            sx={{
                '.column-document_id': {
                    width: '12%',
                },
                '.no': {
                    width: '4%',
                },
                '.heading': {
                    width: '18%',
                },
                '.body': {
                    width: '44%',
                },
                '.phase': {
                    width: '6%',
                },
                '.responsible_organizations': {
                    width: '8%',
                },
                '.co_responsible_organizations': {
                    width: '8%',
                },
                '& .RaDatagrid-rowCell': {
                    height: '100%',
                },
                // https://marmelab.com/react-admin/Datagrid.html#sx-css-api
                // '& .RaDatagrid-thead': {
                //     borderLeftColor: 'transparent',
                //     borderLeftWidth: 5,
                //     borderLeftStyle: 'solid',
                // },
                '& .RaDatagrid-expandIconCell': {
                    pointerEvents: 'none',
                    '& .RaDatagrid-expandIcon': {
                        pointerEvents: 'auto',
                    },
                },
                '& .favorite-button-field': {
                    pointerEvents: 'none',
                    '& .favorite-button-wrapper': {
                        pointerEvents: 'auto',
                    },
                },
            }}
            // expand={<ParagraphShow onClose={() => {}}/>}
            // expandSingle={true}
        >
            {/*<NumberField*/}
            {/*    source="id"*/}
            {/*/>*/}

            <ReferenceField source="document_id" reference="documents" link={false} sortable={false}>
                <TextField source="title" />
            </ReferenceField>

            <NumberField
                headerClassName="no"
                source="sequence"
                // label="Seq"
            />
            {/* <FavoriteField/> */}
            <FavoriteButtonField cellClassName="favorite-button-field" />
            <WrapperField
                source="headings"
                headerClassName="heading"
                sortable={false}
                // label="Heading"
            >
                <InnerHeadingsField />
            </WrapperField>

            <IodMarkdownField
                sortable={false}
                headerClassName="body"
                className="default"
                source="body"
                sx={{
                    // maxWidth: '500px',
                    maxHeight: '80px',
                    // maxHeight: '10rem',
                    overflow: 'hidden',
                    // whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}
                // sx={{ fontFamily: 'Meiryo UI' }}
            />

            <FunctionField
                // label="フェーズ"
                source="phase"
                headerClassName="phase"
                sortable={false}
                render={(record: { phase: ContentPhaseType }) => {
                    switch (record.phase) {
                        case 'yobou':
                            return '予防';
                        case 'oukyu':
                            return '応急';
                        case 'fukkyu':
                            return '復旧';
                        default:
                            return '';
                    }
                }}
            />

            <WrapperField
                // label="主管組織"
                source="responsible_organizations"
                headerClassName="responsible_organizations"
                sortable={false}
            >
                <OrganizationsSearchResultsField type="responsible_organization" />
            </WrapperField>
            <WrapperField
                // label="主管組織"
                source="co_responsible_organizations"
                headerClassName="co_responsible_organizations"
                sortable={false}
            >
                <OrganizationsSearchResultsField type="co_responsible_organization" />
            </WrapperField>
        </Datagrid>
    );
};

// const TabbedDatagrid = ({ selectedRow }: ParagraphListProps) => {
//     const listContext = useListContext();
//     const { filterValues, setFilters, displayedFilters } = listContext;
//     const totals = useGetTotals(filterValues) as any;
//
//     const handleChange = useCallback(
//         (event: React.ChangeEvent<{}>, value: any) => {
//             setFilters &&
//                 setFilters(
//                     { ...filterValues, status: value },
//                     displayedFilters,
//                     false, // no
//                     // , we want the filter to fire immediately
//                 );
//         },
//         [displayedFilters, filterValues, setFilters],
//     );
//
//     return (
//         <Fragment>
//             <Tabs
//                 variant="fullWidth"
//                 centered
//                 value={filterValues.status}
//                 indicatorColor="primary"
//                 onChange={handleChange}
//             >
//                 {tabs.map((choice) => (
//                     <Tab
//                         key={choice.id}
//                         label={totals[choice.id] ? `${choice.name} (${totals[choice.id]})` : choice.name}
//                         value={choice.id}
//                     />
//                 ))}
//             </Tabs>
//             <Divider />
//             <>
//                 {filterValues.status === 'preface' && <DataGridComponent selectedRow={selectedRow} />}
//                 {filterValues.status === 'prevention' && <DataGridComponent selectedRow={selectedRow} />}
//                 {filterValues.status === 'emergency' && <DataGridComponent selectedRow={selectedRow} />}
//                 {filterValues.status === 'restoration' && <DataGridComponent selectedRow={selectedRow} />}
//             </>
//         </Fragment>
//     );
// };

interface Props {
    filters: ReactElement | ReactElement[];
    version?: string;
    fields?: string[];
}

export const ParagraphList = (props: Props) => {
    const resource = useResourceContext();
    const location = useLocation();
    const navigate = useNavigate();
    const sidebarWidth = useSidebarWidth();

    // useListControllerは一覧のフィルタ,データ取得,ページネーションなどを管理する
    // see: https://marmelab.com/react-admin/useListController.html
    const { filterValues, isLoading } = useListController();

    const handleClose = useCallback(() => {
        navigate(`/${resource}`);
    }, [navigate]);

    const match = matchPath(`/${resource}/:id/show`, location.pathname);

    const refElem = useRef(null);

    const { setHierarchicalOrgObject } = React.useContext(HierarchicalOrgContext);

    React.useEffect(() => {
        // filterValuesオブジェクトのキーが0の場合、フィルタが削除されたと見なす
        if (Object.keys(filterValues).length === 0) {
            // フィルターが削除されたときに実行するカスタムロジック
            setHierarchicalOrgObject({});
        }
    }, [filterValues]);

    const [parentHeight, setParentHeight] = React.useState<number>(200);

    if (isLoading) {
        return <CircularProgress />;
    }

    const permanentFilter: any = {};
    if (props.version) {
        permanentFilter.version = props.version;
    }
    if (props.fields) {
        permanentFilter.fields = props.fields;
    }

    const queryOptionForShow = props.version ? { meta: { version: props.version } } : undefined;

    // @see https://marmelab.com/react-admin/List.html#actions
    const ListActions = () => (
        <TopToolbar>
            {/* <SelectColumnsButton /> */}
            {/* 非表示 */}
            <ParagraphFilterButton />
            {/* <CreateButton/> */}
            {/* 非表示 */}
            <ExportButton label="resources.paragraphs.action.export" />
        </TopToolbar>
    );

    const handleChildHeightChange = (newHeight: number) => {
        setParentHeight(newHeight);
    };

    return (
        <Box display="flex">
            <IodList
                sx={{
                    flexGrow: 1,
                    transition: (theme: any) =>
                        theme.transitions.create(['all'], {
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    marginRight: !!match ? '50vw' : 0,
                }}
                debounce={10} // フィルターへの最後の入力からdebounceミリ秒後に検索（フリーワードはEnter or onBlur時に反応する）
                // filterDefaultValues={{status: 'preface'}}
                filters={props.filters}
                // exporter={false} // 出力ボタンを非表示にしたい場合は有効化する
                exporter={paragraphCsvExporter}
                sort={{ field: 'sequence', order: 'ASC' }} // デフォルトのソート順
                // actions={false}
                actions={<ListActions />} // 一覧右上パーツ部
                filter={permanentFilter}
            >
                <DataGridComponent
                    selectedRow={!!match ? (match as any).params.id : undefined}
                    version={props.version}
                />
                {/* タブ表示一旦消す */}
                {/*<TabbedDatagrid*/}
                {/*    selectedRow={*/}
                {/*        !!match*/}
                {/*            ? parseInt((match as any).params.id, 10)*/}
                {/*            : undefined*/}
                {/*    }*/}
                {/*/>*/}
            </IodList>

            <Drawer
                ref={refElem}
                variant="persistent"
                open={!!match}
                anchor="right"
                onClose={handleClose}
                sx={{
                    zIndex: 100,
                    '& .MuiBox-root': {
                        // width: {xs: '100vW', sm: '700px'},
                        // mt: {xs: 2, sm: 1},
                    },
                    '& .MuiCardContent-root': {
                        paddingTop: '10px',
                    },
                    '& .MuiPaper-root': {
                        // overflow: 'hidden',
                        width: { xs: '100vW', sm: `calc(100vw - ${sidebarWidth + 16}px)` },
                        height: '100%',
                    },
                    '& .MuiDrawer-paper': {
                        top: `${parentHeight}px`,
                        height: `calc(100vh - ${parentHeight}px)`,
                    },
                }}
            >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {!!match && (
                    <ShowBase id={(match as any).params.id} queryOptions={queryOptionForShow}>
                        <ApplyFontSize>
                            <ParagraphShow
                                refElem={refElem}
                                onClose={handleClose}
                                onHeightChange={handleChildHeightChange}
                                version={props.version}
                            />
                        </ApplyFontSize>
                    </ShowBase>
                )}
            </Drawer>
        </Box>
    );
};

export const EditingParagraphList = () => {
    // 検索の検索対象を追加したい場合はfieldsプロパティを渡す
    // const fields = ['plain_body', 'embedded_text', 'note'];
    return <ParagraphList filters={editingParagraphFilters} version="pending" />;
};

export const SnapshotParagraphList = () => {
    return <ParagraphList filters={snapshotParagraphFilters} version="latest" />;
};
