import React, { FC } from 'react';
import {
    DateField,
    EditBase,
    Labeled,
    ShowBase,
    SimpleShowLayout,
    TextField,
    Title,
    Toolbar,
    TopToolbar,
    usePermissions,
    useRedirect,
    useShowContext,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Typography } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { BackButton } from '../../../../../components/ui/button/BackButton';
import { SaveButton } from '../../../../../components/ui/button/SaveButton';
import { UserField } from '../../../../../components/ui/fields/UserField';
import { UsersField } from '../../../../../components/ui/fields/UsersField';
import { IodToolbar } from '../../../../../components/ui/layout/IodToolbar';
import { useSidebarWidth } from '../../../../../hooks/useSidebarWidth';
import { DiffsRequestComponent } from '../../../components/DiffsRequestComponent';
import { MergeRequestEditCard } from '../../../components/MergeRequestEditCard';
import { MergeRequestReviewCard } from '../../../components/MergeRequestReviewCard';
import { EditInfo } from '../../../types/editInfo';
import { MergeRequestOperationButtons } from '../../merge-requests/components/MergeRequestOperationButtons';

const scrollBarWidth = 20;

export const EditDetail = () => {
    return (
        <ShowBase>
            <EditDetailInner />
        </ShowBase>
    );
};

const EditDetailInner: FC = () => {
    const { record } = useShowContext<EditInfo>();
    const redirect = useRedirect();

    if (!record) {
        return null;
    }

    const clickMergeRequest = (data: EditInfo) => {
        const diffId = data.id;

        redirect(`/diffs?ids=${diffId}`); // 承認依頼画面
    };

    return (
        <>
            <Title title="編集内容詳細" />
            <TopToolbar sx={{ justifyContent: 'flex-start' }}>
                <BackButton />
            </TopToolbar>
            {!!record.merge_request_id ? (
                // 依頼済みであれば依頼内容も表示
                <MergeRequestEditCard
                    merge_request_id={record.merge_request_id}
                    title="確認・承認依頼情報"
                    sx={{ mb: 2 }}
                    toolbar={
                        <Toolbar>
                            <SaveButton sx={{ m: 1 }} />
                            {(record.review_stats?.status === 'rejected' ||
                                record.review_stats?.status === 'withdrawn') && (
                                <MergeRequestOperationButtons allowedOptions={['reopen']} />
                            )}
                        </Toolbar>
                    }
                />
            ) : (
                <Card sx={{ mb: 2, mt: 0 }}>
                    <CardHeader title="確認・承認依頼情報" />
                    <CardContent>
                        <Typography variant="body1">確認・承認依頼は未作成です。</Typography>
                    </CardContent>
                    <IodToolbar>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={async () => {
                                await clickMergeRequest(record);
                            }}
                        >
                            承認依頼画面へ
                        </Button>
                    </IodToolbar>
                </Card>
            )}
            <DIffCard record={record} />

            {!!record.merge_request_id && (
                // 依頼済みであれば確認・更新者ジョウホウも表示
                <EditBase resource="merge_requests" id={record.merge_request_id}>
                    <MergeRequestReviewCard />
                </EditBase>
            )}
        </>
    );
};

interface DiffCardProps {
    record: EditInfo;
}

const DIffCard = (props: DiffCardProps) => {
    const record = props.record;

    const navigate = useNavigate();
    const sidebarWidth = useSidebarWidth();

    const content_id = record.id;

    // 権限制御
    const { isLoading: isLoadingPermissions, permissions } = usePermissions();
    if (isLoadingPermissions) {
        return <CircularProgress />;
    }
    // const hasApproverPolicy = permissions.documents.includes('approver');

    const handleEdit = async () => {
        // 編集内容一覧から引き継いできたpathに遷移する
        navigate(`/edit/documents/${record?.document_id}/contents/${encodeURIComponent(record.path ?? '')}/show`);
    };

    return (
        // UIデザイン他案：https://mui.com/material-ui/react-grid/
        <Card sx={{ mb: 2 }}>
            <CardHeader title="編集内容" />

            <SimpleShowLayout>
                <TextField source="document_title" />
                <UsersField source="updated_by" />
                <UserField source="last_updated_by" />
                <DateField source="updated_at" showTime={true} />
                <DateField source="user_last_modified_at" showTime={true} />
            </SimpleShowLayout>

            {/* 差分表示: パラグラフ単体 */}
            <DiffsRequestComponent
                content_ids={[content_id]}
                showHistory={true}
                canComment={true}
                sx={{
                    width: `calc(100vw - ${sidebarWidth + scrollBarWidth + 16}px)`,
                }}
            />
            <IodToolbar>
                <CardActions>
                    {!record?.deleted_at && (
                        <Button variant="contained" color="primary" onClick={handleEdit}>
                            <EditIcon fontSize="small" />
                            編集
                        </Button>
                    )}
                </CardActions>
            </IodToolbar>
        </Card>
    );
};
