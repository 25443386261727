import { useEffect } from 'react';
import { Loading, MenuProps, useGetIdentity, usePermissions } from 'react-admin';
import { SolarMenu } from '@react-admin/ra-navigation';
import { useLocation } from 'react-router-dom';
import { ListSubheader } from '@mui/material';
import { Edit, FindInPage } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import { ReviewStatus } from '../../../features/workflow/types/reviewStatsInfo';
import { useSelectedMenu } from '../../../hooks/useSelectedMenu';

/**
 * 左側のサイドメニュー
 */
export const IodSolarSideMenu = (props: MenuProps) => {
    const { permissions, isLoading } = usePermissions();
    const location = useLocation();
    const { data, isLoading: isLoadingId } = useGetIdentity();
    const { selectedMenu, setSelectedMenu } = useSelectedMenu();

    const isMenuActive = (pattern: string) => {
        return location.pathname.startsWith(pattern);
    };

    const isDashboard = location.pathname === '/';
    const isSearch = isMenuActive('/documents') || isMenuActive('/paragraphs');
    const isEdit = isMenuActive('/edit') || isMenuActive('/merge_requests');
    const isAdmin = isMenuActive('/organizations') || isMenuActive('/users') || isMenuActive('/admin');

    useEffect(() => {
        if (isDashboard) {
            setSelectedMenu('dashboard');
        } else if (isSearch) {
            setSelectedMenu('search');
        } else if (isEdit) {
            setSelectedMenu('edit');
        } else if (isAdmin) {
            setSelectedMenu('admin');
        } else {
            setSelectedMenu('other');
        }
    }, [isAdmin, isDashboard, isEdit, isSearch, location.pathname, setSelectedMenu]);
    // 現在のパスが特定のパターンに一致するかどうかを確認

    if (isLoading || isLoadingId) {
        return <Loading />;
    }

    // 権限がある場合のみメニューを表示する
    const canShowDocuments = permissions?.documents?.includes('read');
    const canEditDocuments = ['create', 'update', 'delete'].some((action) => {
        return permissions?.paragraphs?.includes(action);
    });
    const canEditOrganizations = ['create', 'update', 'delete'].some((action) => {
        return permissions?.organizations?.includes(action);
    });
    const canShowUsers = permissions?.users?.includes('read');

    const getStatusesStr = (statuses: ReviewStatus[]) => {
        const quotedStatuses = statuses.map((status) => `"${status}"`);
        return quotedStatuses.join(',');
    };

    const style = selectedMenu === 'edit' ? { backgroundColor: '#de8423' } : undefined;

    // https://marmelab.com/ra-enterprise/modules/ra-navigation#solarlayout
    return (
        <SolarMenu
            sx={
                {
                    // '& .RaSolarPrimarySidebar-root .MuiDrawer-paper': {
                    //     backgroundColor: '#C724B1',
                    //
                    //     '& .MuiButtonBase-root': {
                    //         color: '#ffffff',
                    //     },
                    //     '& .MuiButtonBase-root.Mui-selected': {
                    //         backgroundColor: '#3A3A59',
                    //         color: '#ffffff',
                    //     },
                    // },
                }
            }
            bottomToolbar={<></>}
            {...props}
        >
            <SolarMenu.DashboardItem
                name="dashboard"
                to="/"
                label="ダッシュボード"
                icon={<DashboardIcon />}
                selected={isDashboard}
                className="dashboard-menu-item"
            />
            {canShowDocuments && (
                <SolarMenu.Item
                    name="search"
                    // to="/paragraphs"
                    label="検索"
                    icon={<FindInPage />}
                    selected={isSearch}
                    className="search-menu-item"
                    subMenu={
                        <SolarMenu.List>
                            {/*<SolarMenu.ResourceItem */}
                            {/*    name="documents"*/}
                            {/*    label="目次（現行版）"*/}
                            {/*/>*/}
                            <SolarMenu.Item
                                name="documents"
                                to="/documents"
                                label="目次（現行版）"
                                selected={isMenuActive('/documents')}
                            />
                            <SolarMenu.Item
                                name="paragraphs"
                                to={
                                    // クリアボタンでfilterが解除される課題があるため一旦避ける
                                    // '/paragraphs?filter={"version":"latest"}'
                                    '/paragraphs'
                                }
                                label="検索（現行版）"
                            />
                        </SolarMenu.List>
                    }
                />
            )}

            {canEditDocuments && (
                <SolarMenu.Item
                    name="edit"
                    // to="/paragraphs"
                    label="編集"
                    icon={<Edit />}
                    style={style}
                    selected={isEdit}
                    className="edit-menu-item"
                    subMenu={
                        canEditDocuments && (
                            <SolarMenu.List>
                                <SolarMenu.Item
                                    name="edit/documents"
                                    to="/edit/documents"
                                    label="目次（最新修正案）"
                                    selected={isMenuActive('/edit/documents')}
                                    style={isMenuActive('/edit/documents') ? { backgroundColor: '#de8423' } : undefined}
                                />
                                <SolarMenu.Item
                                    name="edit/paragraphs"
                                    to={
                                        // クリアボタンでfilterが解除される課題があるため一旦避ける
                                        // '/edit/paragraphs?filter={"version":"editing"}'
                                        '/edit/paragraphs'
                                    }
                                    label="検索（最新修正案）"
                                    style={
                                        isMenuActive('/edit/paragraphs') ? { backgroundColor: '#de8423' } : undefined
                                    }
                                />
                                <SolarMenu.Item
                                    name="edits"
                                    to="/edits"
                                    label="編集内容一覧"
                                    style={isMenuActive('/edits') ? { backgroundColor: '#de8423' } : undefined}
                                />
                                <ListSubheader
                                    sx={{
                                        marginTop: 1.5,
                                        lineHeight: 'unset',
                                        paddingX: 1,
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                    }}
                                >
                                    {/*<Typography variant="h6">*/}
                                    確認・承認依頼一覧
                                    {/*</Typography>*/}
                                </ListSubheader>
                                <SolarMenu.Item
                                    sx={{ marginLeft: 1 }}
                                    name="merge_requests.editor"
                                    to={`/merge_requests?filter={"editor":"${data?.id}"}`}
                                    label="自分が編集に関わった依頼"
                                />
                                <SolarMenu.Item
                                    sx={{ marginLeft: 1 }}
                                    name="merge_requests.reviewer"
                                    to={`/merge_requests?filter={"reviewer":"${data?.id}", "statuses": [${getStatusesStr(
                                        ['requested'],
                                    )}] }`}
                                    label="自分宛の依頼"
                                />
                            </SolarMenu.List>
                        )
                    }
                />
            )}

            {(canEditOrganizations || canShowUsers) && (
                <SolarMenu.Item
                    name="admin"
                    label="管理"
                    icon={<SettingsIcon />}
                    selected={isAdmin}
                    className="admin-menu-item"
                    subMenu={
                        <SolarMenu.List>
                            {canEditOrganizations && (
                                <SolarMenu.Item name="organizations" to="/organizations" label="組織一覧" />
                            )}
                            {canShowUsers && <SolarMenu.Item name="users" to="/users" label="ユーザ一覧" />}
                            <SolarMenu.Item name="admin/documents" to="/admin/documents" label="ドキュメント一覧" />

                            <SolarMenu.Item name="public_notice" to="/public_notice" label="一般公開トップページ編集" />
                        </SolarMenu.List>
                    }
                />
            )}
        </SolarMenu>
    );
};
