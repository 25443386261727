import { LineDiscussion } from '../../types/discussion/content-discussion/lineDiscussion';
import { ParagraphDiff, ParagraphDiffLine } from './paragraphDiffProcessor';

/**
 * 中間オブジェクトを作成する為のインタフェース
 *
 * 行ごとの差分オブジェクトに修正理由を組み込む際に使用
 */
interface ReasonInfo {
    original_content_id: number;
    reasons: Reason[];
}
interface Reason {
    line_no: number;
    classification: string;
    reason: string;
}

/**
 * パラグラフ単体の差分を行番号ごとに対応付けしたオブジェクト
 *
 * 行コメント（修正理由）を付与することができる
 */
export interface ParagraphDiffWithReasons extends Omit<ParagraphDiff, 'diffLines'> {
    // diffLinesを除外 -> 修正理由プロパティを持つ新たなdiffLinesを追加
    diffLines: ParagraphDiffLineWithReason[];
}
interface ParagraphDiffLineWithReason extends ParagraphDiffLine {
    classification?: string;
    reason?: string;
    // diffLine: ParagraphDiffLine; 本当はこちらが理想
}

/**
 * 差分情報に対して、行コメント（修正理由）を付与するクラス
 */
export class DiffCommentManager {
    /**
     * 差分情報に対し、修正理由を追加する
     *
     * 1. ディスカッション情報から取り扱いやすい中間オブジェクトを作成する
     * 2. 差分オブジェクトに修正理由を追加する
     */
    public addCommentsToDiffs = (
        paragraphDiffList: ParagraphDiff[],
        discussionsList: LineDiscussion[][],
    ): ParagraphDiffWithReasons[] => {
        // ディスカッション情報から必要な情報だけを取り出して、中間オブジェクトを作成する
        const docxReasonInfoList: ReasonInfo[] = this.createTemporaryReason(discussionsList);

        // 差分オブジェクトに、修正理由（中間オブジェクトに変換済み）を追加する
        const addedReasonToParagraphDiffList: ParagraphDiff[] = paragraphDiffList.map((paragraphDiff) => {
            // console.log(paragraphDiff);

            // 行単位
            const newDiffLines: ParagraphDiffLine[] = paragraphDiff.diffLines.map((diffLine) => {
                // パラグラフに修正理由が存在するか
                const matchingReasonInfo = docxReasonInfoList.find(
                    (reasonInfo: ReasonInfo) => reasonInfo.original_content_id === paragraphDiff.contentId,
                );

                let reason = null;
                let classification = null;
                const afterBody = diffLine.afterBody;
                if (matchingReasonInfo && afterBody) {
                    const matchingReason = matchingReasonInfo.reasons.find(
                        // newNumber: diffsAPIレスポンスにおける編集後データの行番号のこと
                        // 修正理由は編集後データにしか紐づかないので、オブジェクトへの追加も編集後の行番号を元に行う
                        (reason: Reason) => reason.line_no === afterBody.newNumber,
                    );
                    if (matchingReason) {
                        reason = matchingReason.reason || null;
                        classification = matchingReason.classification || null;
                    }
                }

                // 新しい行単位オブジェクトを作成して、副作用が及ばない様にする
                const diffLineCopy: ParagraphDiffLineWithReason = { ...diffLine };
                if (reason !== null) {
                    diffLineCopy.reason = reason;
                }
                if (classification !== null) {
                    diffLineCopy.classification = classification;
                }

                return diffLineCopy;
            });

            const paragraphDiffCopy: ParagraphDiff = { ...paragraphDiff };
            paragraphDiffCopy.diffLines = newDiffLines;
            return paragraphDiffCopy;
        });

        return addedReasonToParagraphDiffList;
    };

    // ディスカッション情報から必要な情報だけを取り出して、中間オブジェクトを作成する
    private createTemporaryReason = (discussionsList: LineDiscussion[][]): ReasonInfo[] => {
        const infoList: ReasonInfo[] = [];

        // 対応は差分:ディスカッション:修正理由 1:1:N
        discussionsList.forEach((discussions: LineDiscussion[]) => {
            discussions.forEach((discussion: LineDiscussion) => {
                // 既出のidは該当オブジェクトに情報格納、初出のidはリストに追加してから情報格納
                let existInfo = infoList.find(
                    (info: ReasonInfo) => info.original_content_id === discussion.content_id,
                );
                if (!existInfo) {
                    const newInfo: ReasonInfo = {
                        original_content_id: discussion.content_id,
                        reasons: [],
                    };
                    infoList.push(newInfo);
                    existInfo = newInfo;
                }

                // 情報を格納する
                existInfo.reasons.push({
                    line_no: discussion.line_no,
                    classification: discussion.classification,
                    reason: discussion.reason,
                });
            });
        });

        return infoList;
    };
}
