import * as React from 'react';
import { Identifier, useGetList } from 'react-admin';
import { DiffInfo } from '../types/diffInfo';
import {IodMultiDiffContainer, IodMultiDiffContainerProps} from './IodMultiDiffContainer';

interface Props extends Omit<IodMultiDiffContainerProps, 'diffList'> {
    content_ids: Array<Identifier>;
}

/**
 * IodDiffContainerのwrapper（名前が変ですが、つまり承認依頼など「依頼(request)」時のDiffComponentという感じ）
 * @param props 
 * @returns 
 */
export const DiffsRequestComponent = (props: Props) => {
    const { content_ids, ...restProps } = props;

    // useGetManyで代替できるはず
    const { data, isLoading } = useGetList<DiffInfo>('documents/diffs', {
        filter: {
            ids: props.content_ids,
        },
    });

    if (isLoading) {
        return null;
    }

    return <IodMultiDiffContainer showHistory={true} diffList={data ?? []} {...restProps} />;
};
