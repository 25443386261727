import React, { FC, useContext, useEffect, useState } from 'react';
import { Labeled, useGetList, useListContext } from 'react-admin';
import {
    Autocomplete,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Tooltip,
    TooltipProps,
    createFilterOptions,
    styled,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { HierarchicalOrgContext } from '../../../components/functional/HierarchicalOrgProvider';
import { httpClient } from '../../../components/functional/dataProvider';
import { API_URL } from '../../../config';
import { Organization, PARENT_PREFIX } from '../../organizations/types/organization';

// 文字サイズを大きくしたツールチップ
const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    '& .MuiTooltip-tooltip': {
        fontSize: '.875em', // 文字サイズの変更
        maxWidth: '325px', // ツールチップの横幅の変更
    },
});

export type HierarchicalOrgDialogProps = {
    source: string;
    label: string;
    alwaysOn?: any;
};

/**
 *  階層型組織選択ダイアログ
 */
export const HierarchicalOrgDialog: FC<HierarchicalOrgDialogProps> = ({ source, label = '組織' }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { hierarchicalOrgObject, setHierarchicalOrgObject } = useContext(HierarchicalOrgContext);

    // ダイアログの操作関数
    const handleClickOpen = (e: React.MouseEvent) => {
        setIsOpen(true);
    };
    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        setIsOpen(false);
    };

    // propsで受け取ったsourceをキーにして、このコンポーネントで表示されているデータを取得する
    const hierarchicalOrgArray = hierarchicalOrgObject[source] ?? [null];
    //
    const { filterValues } = useListContext();

    // 保存済みの検索条件を適用した際、選択した組織が画面に表示されるようにする
    useEffect(() => {
        // 画面に表示されている組織のidと、フィルターに使用されている組織のidを取得
        const currentDisplayId =
            hierarchicalOrgArray.length === 1 ? undefined : hierarchicalOrgArray[hierarchicalOrgArray.length - 2]?.id;
        const currentFilterId = filterValues[source];

        if (currentDisplayId !== currentFilterId) {
            // 表示されている組織とフィルターで使用されている組織に差異がある場合
            if (currentFilterId) {
                // フィルターで使用されている組織idがundefinedでない場合、組織名データをapiから取得
                httpClient(`${API_URL}/organizations/${currentFilterId}`).then(({ json }): void => {
                    setHierarchicalOrgObject({
                        ...hierarchicalOrgObject,
                        [source]: [json, null],
                    });
                });
            } else {
                // undefinedの場合は画面表示の組織をクリア
                setHierarchicalOrgObject({
                    ...hierarchicalOrgObject,
                    [source]: [null],
                });
            }
        }
    }, [filterValues]);

    return (
        <>
            <Labeled label={label}>
                <Button variant="outlined" color="success" size="small" onClick={handleClickOpen}>
                    {(hierarchicalOrgObject[source]?.length ?? 1) === 1
                        ? `${label}を選択`
                        : hierarchicalOrgObject[source]?.map((item, index, me) => {
                              return (
                                  <span key={index}>
                                      {item?.name ?? ''}
                                      {(me[index + 1] ?? null) !== null && (
                                          <span
                                              style={{
                                                  display: 'inline-block',
                                                  marginInline: '4px',
                                                  opacity: 0.815,
                                              }}
                                          >
                                              {'>'}
                                          </span>
                                      )}
                                  </span>
                              );
                          })}
                </Button>
            </Labeled>

            <Dialog open={isOpen} maxWidth="md" onClose={handleClose}>
                <DialogTitle
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '.5rem',
                    }}
                >
                    {`${label}選択`}
                    <CustomTooltip
                        title={
                            <>
                                ★は、その下に複数の組織があることを表します。
                                <br />
                                その数を末尾に「(37)」と記載しています。
                            </>
                        }
                    >
                        <HelpIcon
                            sx={{
                                color: '#6e6e6e',
                            }}
                        />
                    </CustomTooltip>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row" spacing={2}>
                        <HierarchicalOrgInputContainer source={source} label={label} />
                    </Stack>
                </DialogContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '1.5rem',
                        paddingTop: '0',
                    }}
                >
                    <Button variant="contained" color="secondary" size="large" onClick={handleClose}>
                        閉じる
                    </Button>
                </div>
            </Dialog>
        </>
    );
};

export type HierarchicalOrgInputContainerProps = {
    source: string;
    label: string;
    alwaysOn?: any;
};

/**
 *  階層構造になった組織のセレクトボックスをまとめるコンテナコンポーネント
 */
export const HierarchicalOrgInputContainer: FC<HierarchicalOrgInputContainerProps> = ({ source, label = '組織' }) => {
    // フィルタリング操作のためにuseListContextを読み込む
    const { filterValues, setFilters, displayedFilters } = useListContext();

    // propsで受け取ったsourceをキーにして、このコンポーネントで使用するデータを取得する
    const { hierarchicalOrgObject, setHierarchicalOrgObject } = useContext(HierarchicalOrgContext);
    const hierarchicalOrgArray = hierarchicalOrgObject[source] ?? [null];

    // セレクトボックスの値が更新されたときに呼び出される関数
    const changeValue = (index: number, value: Organization | null) => {
        // organizationValueArrayをディープコピー
        const newHierarchicalOrgArray = [...(hierarchicalOrgObject[source] ?? [])];
        // 指定されたインデックス以降の要素を削除して、新しいvalueを挿入
        newHierarchicalOrgArray.splice(index);
        if (value !== null) {
            newHierarchicalOrgArray.push({ ...value });
        }
        // 新しいAutoComplete用
        newHierarchicalOrgArray.push(null);

        // フィルターに最も子の組織idをセット
        // 何も選択されていない場合はFilterを削除したいためundefined
        const nextFilter: number | undefined =
            newHierarchicalOrgArray.length === 1
                ? undefined
                : (newHierarchicalOrgArray[newHierarchicalOrgArray.length - 2]?.id as number);

        // ReactAdminのFiltersにセット
        setFilters(
            {
                ...filterValues,
                [source]: nextFilter,
            },
            displayedFilters,
        );

        // 大元の値を更新
        setHierarchicalOrgObject({
            ...hierarchicalOrgObject,
            [source]: newHierarchicalOrgArray,
        });
    };

    return (
        <>
            {hierarchicalOrgArray.map((value, index) => (
                <HierarchicalOrgAutocompleteInput
                    key={index}
                    label={label}
                    value={value}
                    changeValue={(_, value) => changeValue(index, value)}
                    parentValue={index === 0 ? null : hierarchicalOrgArray[index - 1]}
                />
            ))}
        </>
    );
};

type HierarchicalOrgAutocompleteInputProps = {
    label: string;
    value: Organization | null;
    parentValue: Organization | null;
    changeValue: (event: React.SyntheticEvent<Element, Event>, value: Organization | null) => void;
};

/**
 *  組織選択コンポーネント
 */
const HierarchicalOrgAutocompleteInput: FC<HierarchicalOrgAutocompleteInputProps> = ({
    label,
    value,
    parentValue,
    changeValue,
}) => {
    // parentIdをフィルターに、organizationからデータの取得を行う
    const { data, isLoading } = useGetList<Organization>('organizations', {
        filter: {
            parent_id: parentValue?.id,
        },
    });

    const filterOptions = createFilterOptions<Organization>({
        matchFrom: 'any',
        stringify: (option) => `${option.name} ${option.name_kana} ${katakanaToHiragana(option.name_kana ?? '')}`,
    });

    const getOptionLabel = (record: Organization) => {
        // 表示名に親を表現するプレフィックスを付与
        const prefix = record.children.length > 0 ? PARENT_PREFIX : '';
        const suffix = record.children.length > 0 ? ` (${record.children.length})` : '';
        return `${prefix}${record.name}${suffix}`;
    };

    return (
        <>
            {(data?.length ?? 0) === 0 ? (
                <></>
            ) : (
                <Autocomplete
                    options={data ?? []}
                    getOptionLabel={getOptionLabel}
                    fullWidth
                    sx={{ minWidth: '250px' }}
                    renderInput={(params) => (
                        <TextField {...params} label={`${parentValue ? parentValue.name + 'の子' : ''}${label}`} />
                    )}
                    onChange={changeValue}
                    value={value}
                    // 警告回避に必要
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    filterOptions={filterOptions}
                />
            )}
        </>
    );
};

const katakanaToHiragana = (str: string) => {
    return str.replace(/[\u30a1-\u30f6]/g, (match) => String.fromCharCode(match.charCodeAt(0) - 0x60));
};
