import React from 'react';
import { Button, useDataProvider, useNotify } from 'react-admin';
import { Alert } from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import { httpClient } from '../../../components/functional/dataProvider';
import { API_URL } from '../../../config';
import { DiffInfo } from '../types/diffInfo';
import { LineDiscussion } from '../types/discussion/content-discussion/lineDiscussion';
import { DocxExporter } from './docx-exporter/docxExporter';

interface Props {
    /**
     * クリックしたときにダウンロードする対象のリスト
     */
    originalContentIds: number[];
}

/**
 * 新旧対照表をダウンロードするためのボタン
 */
export const ComparisonTableDownloadButton = (props: Props) => {
    const { originalContentIds } = props;

    const notify = useNotify();
    const dataProvider = useDataProvider();

    const handleDownloadClick = async () => {
        if (originalContentIds.length === 0) {
            notify(<Alert severity="info">エクスポートしたい内容を選択してください。</Alert>);
            return;
        }

        const res = await dataProvider.getList('documents/diffs', {
            pagination: { page: 1, perPage: 9999 }, // GET diffs APIはページネーションはないが形式的に適用
            sort: { field: 'id', order: 'ASC' }, // idの昇順
            filter: {
                ids: originalContentIds,
            },
        });

        const diffs: DiffInfo[] = res.data;

        let discussionsList: LineDiscussion[][] = []; // ディスカッション配列の変数
        // 全ての非同期処理を並列で実行する
        await Promise.all(
            diffs.map(async (diff) => {
                const content_path = diff.new_path || diff.old_path || '';
                const apiPath = `${API_URL}/documents/${diff.document_id}/${encodeURIComponent(content_path)}/commits/${
                    diff.new_revision_id
                }/discussions`;

                const res = await httpClient(apiPath);
                const discussions: LineDiscussion[] = res.json;
                discussionsList = [...discussionsList, discussions];
            }),
        );

        // Word出力処理を起動
        const exporter = new DocxExporter();
        await exporter.export({
            diffs: diffs,
            discussionsList: discussionsList,
        });
    };

    return (
        <Button
            startIcon={<FileDownload />}
            label="新旧対照表エクスポート（Word形式）"
            onClick={handleDownloadClick}
            disabled={!originalContentIds || originalContentIds.length === 0}
        />
    );
};
