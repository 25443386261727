import { memo, useState } from 'react';
import { useGetIdentity, useRecordContext } from 'react-admin';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MoreVert } from '@mui/icons-material';
import { UserProfileIcon } from '../../../../components/ui/elements/UserProfileIcon';
import { formatDate } from '../../../../utils/dateUtil';
import { Comment, CommentUpdate } from '../../types/comment';
import { CommentEditForm, CommentFormValue } from './CommentEditForm';

interface Props {
    comment: Comment;
    onEditReply: (commentUpdate: CommentUpdate) => void;
    onDeleteReply: () => void;
    onEditing?: (isEditing: boolean) => void; // 編集状態の変更を伝えるハンドラ
}

export const DiscussionReplyContent = memo((props: Props) => {
    const { comment, ...restProps } = props;
    const { data: identity, isLoading: identityLoading } = useGetIdentity();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const [editMode, setEditMode] = useState(false);
    const [formValue, setFormValue] = useState<CommentFormValue>({
        comment: comment.body,
    });

    const switchMode = (editMode: boolean) => {
        setEditMode(editMode);
        // 高さを検知できるまで若干のタイムラグがあるため100ms待つ
        setTimeout(() => {
            if (restProps.onEditing) {
                restProps.onEditing(editMode);
            }
        }, 100);
    };

    const isFormValid = () => {
        return formValue.comment;
    };

    const handleFormChange = (formValue: CommentFormValue) => {
        setFormValue(formValue);
    };

    const handleSubmit = () => {
        const sendingData: CommentUpdate = {
            body: formValue.comment,
        };
        restProps.onEditReply(sendingData);
        switchMode(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        // メニューの表示位置を適切に制御するためにanchorElを利用する
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        handleClose();
        // 編集処理
        switchMode(true);
    };

    const handleDelete = () => {
        if (window.confirm('削除してよろしいですか？（元に戻せません）')) {
            handleClose();
            switchMode(false);
            restProps.onDeleteReply();
        }
    };

    const isOwner = comment.author.id === identity?.id;

    return (
        <Card variant="outlined">
            <CardHeader
                avatar={<UserProfileIcon userId={comment?.author?.id} />}
                title={`【${comment.author.affiliation}】${comment.author.name}`}
                subheader={formatDate(comment.created_at)}
                action={
                    <>
                        {isOwner && (
                            <>
                                <IconButton aria-label="settings" onClick={handleClick}>
                                    <MoreVert />
                                </IconButton>
                                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                                    <MenuItem onClick={handleEdit}>編集</MenuItem>
                                    <MenuItem onClick={handleDelete} sx={{ color: theme.palette.error.main }}>
                                        削除
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </>
                }
                sx={{
                    py: 1,
                }}
            />
            <CardContent
                sx={{
                    py: 1,
                }}
            >
                {editMode ? (
                    <CommentEditForm formValue={formValue} handleChange={handleFormChange} />
                ) : (
                    <>
                        <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                            {comment.body}
                        </Typography>
                    </>
                )}
            </CardContent>
            {editMode && (
                <CardActions>
                    {/*<SaveButton*/}
                    {/*    onClick={(e) => {*/}
                    {/*        // 正確に制御するにはuseUpdateを使って更新のAPIコールのレスポンスを待って制御するべきだが必要十分と判断*/}
                    {/*        setTimeout(() => {*/}
                    {/*            setEditMode(false);*/}
                    {/*        }, 500);*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <Button variant="outlined" onClick={() => setEditMode(false)}>
                        キャンセル
                    </Button>
                    {/*<CancelButton onClick={() => setEditMode(false)}/>*/}
                    <Button variant="contained" disabled={!isFormValid()} onClick={handleSubmit}>
                        保存
                    </Button>
                </CardActions>
            )}
        </Card>
    );
});
