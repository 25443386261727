import { FC } from 'react';
import {Labeled, RichTextField, Show, SimpleForm, SimpleFormProps, useGetList, useShowContext} from 'react-admin';
import { CardHeader, CardHeaderProps, SxProps } from '@mui/material';
import { Identifier } from 'ra-core';
import { UserField } from '../../../components/ui/fields/UserField';
import { MergeRequestInfo } from '../types/mergeRequest';
import { ReviewStatusStepper } from './ReviewStatusStepper';
import {FavoriteItem} from '../../favorites/types/favoriteItem';

interface MergeRequestShowCardProps
    extends Pick<SimpleFormProps, 'toolbar' | 'onSubmit'>,
        Pick<CardHeaderProps, 'action'> {
    title: string;
    sx?: SxProps;
    merge_request_id: Identifier;
}

export const MergeRequestShowCard: FC<MergeRequestShowCardProps> = ({
    title,
    action: headerAction,
    sx,
    merge_request_id,
    ...props
}) => {
    return (
        <Show resource="merge_requests" id={merge_request_id} title={<></>} sx={sx} actions={<></>}>
            <CardHeader title={title} action={headerAction} />
            <MergeRequestShowInner {...props} />
        </Show>
    );
};

interface MergeRequestShowInnerProps extends Pick<SimpleFormProps, 'toolbar'> {}

const MergeRequestShowInner: FC<MergeRequestShowInnerProps> = ({ ...props }) => {
    const context = useShowContext<MergeRequestInfo>();
    const original_content_id = context.record?.content?.content_id;

    // const apiUrl = `merge_requests/${context.record?.id}/discussions`;
    // const { data, isLoading, refetch } = useGetList<any>(apiUrl);

    return (
        <SimpleForm toolbar={props.toolbar}>
            <Labeled label="依頼者" sx={{ mb: 2.5 }}>
                <UserField source="created_by" />
            </Labeled>

            <Labeled label="依頼タイトル" sx={{ mb: 2.5, width: '100%' }}>
                <RichTextField
                    source="title"
                    sx={{
                        backgroundColor: '#F6F6F6',
                        padding: '0.75rem',
                        borderBottom: '1px solid gray',
                        overflow: 'auto',
                        whiteSpace: 'pre-line',
                    }}
                />
            </Labeled>
            <Labeled label="補足事項" sx={{ mb: 2.5, width: '100%' }}>
                <RichTextField
                    source="description"
                    sx={{
                        backgroundColor: '#F6F6F6',
                        padding: '0.75rem',
                        borderBottom: '1px solid gray',
                        overflow: 'auto',
                        whiteSpace: 'pre-line',
                        maxHeight: 200,
                    }}
                />
            </Labeled>
            <Labeled label="ステータス" sx={{ mb: 2.5 }}>
                <ReviewStatusStepper isRequested={true} reviewStats={context.record?.review_stats} />
            </Labeled>
        </SimpleForm>
    );
};
