import { useState } from 'react';
import {
    DateField,
    Labeled,
    RecordContextProvider,
    SimpleForm,
    SimpleShowLayout,
    useCreate,
    useGetOne,
    useNotify,
    usePermissions,
    useRefresh,
} from 'react-admin';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { Alert, Box, Card, CardActions, CardContent, CardHeader, CircularProgress } from '@mui/material';
import { CancelButton } from '../../components/ui/button/CancelButton';
import { EditButton } from '../../components/ui/button/EditButton';
import { SaveButton } from '../../components/ui/button/SaveButton';
import IodMarkdownField from '../../components/ui/fields/IodMarkdownField';
import IodMarkdownInput from '../../components/ui/inputs/IodMarkdownInput';
import { IodToolbar } from '../../components/ui/layout/IodToolbar';
import { FormPublicNotice, PublicNotice } from './types/publicNotice';

export const PublicNoticeEdit = () => {
    const { permissions, isLoading: isLoadingPermissions } = usePermissions();
    const [isEditMode, setIsEditMode] = useState(false);
    const { data: publicNotice, isLoading } = useGetOne<PublicNotice>(
        'public_notice',
        { id: 'latest' },
        // { enabled: !isEditMode },
    );

    const handleClickEdit = (e: React.MouseEvent) => {
        e.preventDefault(); // 本来のEditButtonの機能を止める
        setIsEditMode(true);
    };

    // 関係者用のお知らせを更新する権限があれば、一般公開用のお知らせも更新を許可する
    const hasEditPolicy = permissions.information.includes('update');

    if (isLoading || isLoadingPermissions) {
        return <CircularProgress />;
    }

    return (
        <RecordContextProvider value={publicNotice}>
            <Card elevation={3}>
                <CardHeader
                    subheader="一般公開サービスのお知らせを表示しています。編集ボタンをクリックして、内容の更新を行うことができます。"
                    color="textSecondary"
                />
                {hasEditPolicy && isEditMode ? (
                    // 編集モード
                    <PublicNoticeEditForm
                        onSave={() => setIsEditMode(false)}
                        onCancel={() => setIsEditMode(false)} // 行う処理は同様だが異なるイベントなので分けている
                    />
                ) : (
                    // 閲覧モード
                    <>
                        <CardContent>
                            {publicNotice ? (
                                <SimpleShowLayout sx={{ p: 0 }}>
                                    <DateField source="updated_at" showTime={true} label="最終更新日時" />
                                    <Labeled label="現在の内容" sx={{ mt: 2 }}>
                                        <Box sx={{ border: '1px solid #e0e0e0', borderRadius: 1, p: 2, pt: 0 }}>
                                            <IodMarkdownField source="body" />
                                        </Box>
                                    </Labeled>
                                </SimpleShowLayout>
                            ) : (
                                <Alert severity="info" sx={{ width: '50%', textAlign: 'left' }}>
                                    {'一般公開サービス向けのお知らせはありません。'}
                                </Alert>
                            )}
                        </CardContent>
                        <CardActions sx={{ mb: 2, px: 2 }}>
                            <EditButton onClick={handleClickEdit} />
                        </CardActions>
                    </>
                )}
            </Card>
        </RecordContextProvider>
    );
};

interface Props {
    onSave: () => void;
    onCancel: () => void;
}

const PublicNoticeEditForm = (props: Props) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const [create] = useCreate<FormPublicNotice>();

    const handleSave = async (fieldValues: FieldValues) => {
        try {
            await create('public_notice', { data: { body: fieldValues.body || '' } });
            notify(<Alert severity="info">{'一般公開サービスのお知らせを更新しました。'}</Alert>);

            props.onSave(); // 編集モードを終了する
            setTimeout(() => {
                refresh();
            }, 1000); // 一旦タイムアウトで待ってから実行
        } catch (error) {
            console.error(error);
            notify(<Alert severity="error">{'一般公開サービスのお知らせの更新に失敗しました。'}</Alert>);
        }
    };

    const handleCancel = (e: React.MouseEvent) => {
        e.preventDefault(); // 本来のEditButtonの機能を止める
        props.onCancel(); // 編集モードを終了する
    };

    return (
        <SimpleForm
            onSubmit={handleSave}
            toolbar={
                <IodToolbar className="PublicNoticeEdit-Toolbar">
                    <SaveButton />
                    <CancelButton onClick={handleCancel} />
                </IodToolbar>
            }
        >
            <IodMarkdownInput name="body" label="お知らせの内容" source="body" />

            <Alert severity="warning" sx={{ width: '100%', textAlign: 'left' }}>
                {'保存された内容は、一般公開サービスのお知らせに表示されます。'}
            </Alert>
        </SimpleForm>
    );
};
