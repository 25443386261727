import { FC } from 'react';
import {
    Edit,
    Labeled,
    SimpleForm,
    SimpleFormProps,
    TextInput,
    useEditContext,
    useNotify,
    useRefresh,
    useUpdate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { Alert, CardHeader, CardHeaderProps, SxProps } from '@mui/material';
import { Identifier } from 'ra-core';
import { UserField } from '../../../components/ui/fields/UserField';
import {
    DESCRIPTION_MAX_LENGTH,
    MergeRequestInfo,
    MergeRequestUpdatePayLoad,
    TITLE_MAX_LENGTH,
} from '../types/mergeRequest';
import { ReviewStatusStepper } from './ReviewStatusStepper';

interface MergeRequestEditCardProps
    extends Pick<SimpleFormProps, 'toolbar' | 'onSubmit'>,
        Pick<CardHeaderProps, 'action'> {
    title: string;
    sx?: SxProps;
    merge_request_id: Identifier;
}

export const MergeRequestEditCard: FC<MergeRequestEditCardProps> = ({
    title,
    action: headerAction,
    sx,
    merge_request_id,
    ...props
}) => {
    const editSx: SxProps = {
        ...sx,
        '& .RaEdit-main': {
            mt: 0,
        },
    };
    return (
        <Edit resource="merge_requests" id={merge_request_id} title={<></>} sx={editSx}>
            <CardHeader title={title} action={headerAction} />
            <MergeRequestEditInner {...props} />
        </Edit>
    );
};

interface MergeRequestEditInnerProps extends Pick<SimpleFormProps, 'toolbar'> {}

const MergeRequestEditInner: FC<MergeRequestEditInnerProps> = ({ ...props }) => {
    const context = useEditContext<MergeRequestInfo>();
    const refresh = useRefresh();
    const notify = useNotify();
    const [update] = useUpdate();

    const original_content_id = context.record?.content?.content_id;

    const saveClick = async (data: FieldValues) => {
        // Request Parameter
        const sendData: MergeRequestUpdatePayLoad = {
            title: data.title,
            description: data.description,
            reviewer_ids: data.reviewer_ids,
        };

        await update(
            'merge_requests',
            {
                id: data.id,
                data: sendData,
            },
            {
                onSuccess: () => {
                    notify(<Alert severity="info">保存しました</Alert>);
                    refresh();
                },
                onError: (error: any) => {
                    notify(<Alert severity="error">保存に失敗しました</Alert>);
                    // notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>)
                },
            },
        );
    };

    return (
        <SimpleForm {...props} onSubmit={saveClick}>
            <Labeled label="依頼者" sx={{ mb: 2.5 }}>
                <UserField source="created_by" />
            </Labeled>

            <Labeled label="確認・承認依頼情報を編集" sx={{ mt: 2.5, mb: 2.5, width: '100%' }}>
                <>
                    <TextInput
                        required
                        source="title"
                        label="依頼タイトル"
                        inputProps={{
                            maxLength: TITLE_MAX_LENGTH,
                            placeholder: `編集内容の簡潔な説明を${TITLE_MAX_LENGTH}文字以内で記入ください`,
                        }}
                        fullWidth
                        multiline
                        maxRows={5}
                    />
                    <TextInput
                        source="description"
                        label="補足事項"
                        fullWidth
                        inputProps={{
                            maxLength: DESCRIPTION_MAX_LENGTH,
                            placeholder:
                                '確認者（同僚や上長）・承認者（総合防災部の担当）への申し送り事項を記入ください',
                        }}
                        multiline
                        maxRows={5}
                    />
                </>
            </Labeled>
            <Labeled label="ステータス" sx={{ mb: 2.5 }}>
                <ReviewStatusStepper isRequested={true} reviewStats={context.record?.review_stats} />
            </Labeled>
        </SimpleForm>
    );
};
