import { memo, useState } from 'react';
import { useGetIdentity, useRecordContext } from 'react-admin';
import { Button, CardActions, CardContent, CardHeader, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MoreVert } from '@mui/icons-material';
import { UserProfileIcon } from '../../../../../components/ui/elements/UserProfileIcon';
import { formatDate } from '../../../../../utils/dateUtil';
import { Comment } from '../../../types/comment';
import { LineDiscussionUpdate } from '../../../types/discussion/content-discussion/lineDiscussion';
import { LineDiscussionForm, LineDiscussionFormValue } from './LineDiscussionForm';

interface Props {
    classification: string;
    reason: string;
    comment: Comment;
    onEditDiscussion: (discussionUpdate: LineDiscussionUpdate) => void;
    onDeleteDiscussion: () => void;
    onEditing?: (isEditing: boolean) => void; // 編集状態の変更を伝えるハンドラ
}

export const LineDiscussionCommentContent = memo((props: Props) => {
    const { comment, reason, classification, ...restProps } = props;

    const { data: identity, isLoading: identityLoading } = useGetIdentity();

    const [formValue, setFormValue] = useState<LineDiscussionFormValue>({
        classification: classification,
        comment: comment.body,
        reason: reason,
    });

    const handleFormChange = (formValue: LineDiscussionFormValue) => {
        setFormValue(formValue);
    };

    const isFormValid = () => {
        return formValue.classification && formValue.reason;
    };

    const [editMode, setEditMode] = useState(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();

    const switchMode = (editMode: boolean) => {
        setEditMode(editMode);
        // 高さを検知できるまで若干のタイムラグがあるため100ms待つ
        setTimeout(() => {
            if (restProps.onEditing) {
                restProps.onEditing(editMode);
            }
        }, 100);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        // メニューの表示位置を適切に制御するためにanchorElを利用する
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        handleClose();
        switchMode(true);
    };

    const handleSubmit = () => {
        const sendingData: LineDiscussionUpdate = {
            // line_no:
            comment: formValue.comment,
            classification: formValue.classification,
            reason: formValue.reason,
            // resolved: '',
        };
        restProps.onEditDiscussion(sendingData);
        switchMode(false);
    };

    const handleDelete = () => {
        if (window.confirm('削除してよろしいですか？（元に戻せません）')) {
            handleClose();
            restProps.onDeleteDiscussion();
        }
        handleClose();
        switchMode(false);
    };

    const isOwner = comment.author.id === identity?.id;

    return (
        <>
            <CardHeader
                avatar={<UserProfileIcon userId={comment?.author?.id} />}
                title={`【${comment.author.affiliation}】${comment.author.name}`}
                subheader={formatDate(comment.created_at)}
                action={
                    <>
                        {isOwner && (
                            <>
                                <IconButton aria-label="settings" onClick={handleClick}>
                                    <MoreVert />
                                </IconButton>
                                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                                    <MenuItem onClick={handleEdit}>編集</MenuItem>
                                    <MenuItem onClick={handleDelete} sx={{ color: theme.palette.error.main }}>
                                        削除
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </>
                }
                sx={{
                    py: 1,
                }}
            />
            <CardContent
                sx={{
                    py: 1,
                }}
            >
                {editMode ? (
                    <LineDiscussionForm formValue={formValue} handleChange={handleFormChange} />
                ) : (
                    <>
                        {/*// なぜか無限ループエラーになるのであきらめ*/}
                        {/*<TextField*/}
                        {/*    label="修正理由の分類"*/}
                        {/*    variant="filled"*/}
                        {/*    defaultValue={classification}*/}
                        {/*    fullWidth*/}
                        {/*    InputProps={{*/}
                        {/*        readOnly: true,*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <Typography sx={{ fontSize: 12, mb: 1.5 }} gutterBottom component="div">
                            修正分類：{classification}
                        </Typography>
                        {/*なぜか無限ループエラーになるのであきらめ*/}
                        {/*<TextField*/}
                        {/*    // sx={{ mb: 1.5 }}*/}
                        {/*    label="修正理由"*/}
                        {/*    // variant="standard"*/}
                        {/*    defaultValue={reason}*/}
                        {/*    fullWidth*/}
                        {/*    multiline*/}
                        {/*    minRows={1}*/}
                        {/*    InputProps={{*/}
                        {/*        readOnly: true,*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <Typography sx={{ fontSize: 14, mb: 1.5 }} style={{ whiteSpace: 'pre-line' }} gutterBottom>
                            修正理由：{reason}
                        </Typography>

                        <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                            {comment.body}
                        </Typography>
                    </>
                )}
            </CardContent>
            {editMode && (
                <CardActions>
                    {/*<SaveButton*/}
                    {/*    onClick={(e) => {*/}
                    {/*        // 正確に制御するにはuseUpdateを使って更新のAPIコールのレスポンスを待って制御するべきだが必要十分と判断*/}
                    {/*        setTimeout(() => {*/}
                    {/*            setEditMode(false);*/}
                    {/*        }, 500);*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <Button variant="outlined" onClick={() => setEditMode(false)}>
                        キャンセル
                    </Button>
                    {/*<CancelButton onClick={() => setEditMode(false)}/>*/}
                    <Button variant="contained" disabled={!isFormValid()} onClick={handleSubmit}>
                        保存
                    </Button>
                </CardActions>
            )}
        </>
    );
});
