import { Identifier, RaRecord } from 'react-admin';
import { ReviewInfo } from './reviewInfo';
import { ReviewStatsInfo } from './reviewStatsInfo';
import {UserInfo} from '../../users/types/userInfo';

interface MergeRequestBase {
    title: string;
    description: string;
}

/**
 * マージリクエストを作成するとき用のインタフェース。
 */
export interface MergeRequestCreatePayLoad extends MergeRequestBase {
    content_ids: number[];
    reviewer_ids: string[];
}

/**
 * マージリクエストを更新するとき用のインタフェース。
 */
export interface MergeRequestUpdatePayLoad extends MergeRequestBase {
    reviewer_ids: string[];
}

export const TITLE_MAX_LENGTH: number = 50;
export const DESCRIPTION_MAX_LENGTH: number = 5000;

export interface MergeRequestInfo extends RaRecord {
    id: Identifier;
    title: string;
    description: string;
    review_stats: ReviewStatsInfo;
    reviews: ReviewInfo[];
    content?: MergeRequestContentInfo;
    created_by?: UserInfo;
    updated_by?: UserInfo;
    created_at: string;
    updated_at: string;
}

interface MergeRequestContentInfo {
    content_id: number;
    document_id: number;
    document_title: string;
    heading_path: string;
    path: string;
    body: string;
    created_at: Date;
    updated_at: Date;
    deleted_at?: Date;
}
