import * as React from 'react';
import { AdminUI, CoreAdminUIProps, CustomRoutes, Loading, Resource, usePermissions } from 'react-admin';
import { Route } from 'react-router-dom';
import { Accessibility, Category, Edit, FindInPage, ListAlt } from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import './App.scss';
import { Dashboard } from './components/page/Dashboard';
import { IodSolarLayout } from './components/ui/layout/IodSolarLayout';
import { ComparisonTableExportPage } from './features/comparison-table/ComparisonTableExportPage';
import { PublicNoticeEdit } from './features/components-for-public/PublicNoticeEdit';
import { ContentEditPage } from './features/contents/components/ContentEditPage';
import { ContentReadOnlyTree } from './features/contents/components/ContentReadOnlyTree';
import { ContentTree } from './features/contents/components/ContentTree';
import { DocumentCreate } from './features/documents/components/DocumentCreate';
import { DocumentEdit } from './features/documents/components/DocumentEdit';
import { DocumentList } from './features/documents/components/DocumentList';
import { EditingDocumentList } from './features/documents/components/EditingDocumentList';
import { LatestDocumentList } from './features/documents/components/LatestDocumentList';
import { OrganizationCreate } from './features/organizations/components/OrganizationCreate';
import { OrganizationEdit } from './features/organizations/components/OrganizationEdit';
import { OrganizationList } from './features/organizations/components/OrganizationList';
import { EditingParagraphList, ParagraphList, SnapshotParagraphList } from './features/paragraphs/ParagraphList';
import { SnapshotCreate } from './features/snapshots/components/SnapshotCreate';
import { SnapshotEdit } from './features/snapshots/components/SnapshotEdit';
import { SnapshotList } from './features/snapshots/components/SnapshotList';
import { UserEdit } from './features/users/components/UserEdit';
import { UserList } from './features/users/components/UserList';
//import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Settings from './features/usersettings/UserSettings';
import { MergeRequestsCreate } from './features/workflow/pages/diffs/components/MergeRequestsCreate';
import { EditDetail } from './features/workflow/pages/edits/components/EditDetail';
import { EditList } from './features/workflow/pages/edits/components/EditList';
import { MergeRequestDetail } from './features/workflow/pages/merge-requests/components/MergeRequestDetail';
import { MergeRequestList } from './features/workflow/pages/merge-requests/components/MergeRequestList';

const adminUIProps: CoreAdminUIProps = {
    title: '東京都地域防災計画データベース',
    // basename: '東京都地域防災計画データベース',
    dashboard: Dashboard,
    // layout: CommonLayout,
    layout: IodSolarLayout,
    loginPage: false, // CognitoのAuthenticatorでラップするため使用しない
};

export const IodAdminUI = () => {
    const { permissions, isLoading } = usePermissions();

    if (isLoading) {
        return <Loading />;
    }

    // 権限がある場合のみメニューを表示する
    const canShowDocuments = permissions?.documents?.includes('read');
    const canEditParagraphs = ['create', 'update', 'delete'].some((action) => {
        return permissions?.paragraphs?.includes(action);
    });
    const canEditDocuments = ['create', 'update', 'delete'].some((action) => {
        return permissions?.documents?.includes(action);
    });
    const canEditOrganizations = ['create', 'update', 'delete'].some((action) => {
        return permissions?.organizations?.includes(action);
    });
    const canShowUsers = permissions?.users?.includes('read');

    return (
        <AdminUI ready={Loading} disableTelemetry {...adminUIProps}>
            {/* 以下、今後の参考のためデフォルトのコードをコメントで残しています */}
            {/*<Resource name="posts" list={PostList} edit={PostEdit} create={PostCreate} icon={PostIcon}/>*/}

            {/* {canShowDocuments && (
                <Resource
                    name="documents/1/contents_view" // TODO 1を動的に変更できるようにする必要がある。
                    list={ContentReadOnlyTree}
                    options={{ label: '目次' }}
                    icon={AccountTreeIcon}
                />
            )} */}

            {canShowDocuments && (
                /*
                 * Nested Resources:
                 * https://marmelab.com/react-admin/Resource.html#nested-resources
                 */
                <>
                    <Resource
                        name="documents"
                        list={LatestDocumentList}
                        // show={ContentReadOnlyTree}
                        icon={AccountTreeIcon}
                    >
                        {/*<Route path="/documents" element={<DocumentListWithContext />} />*/}
                        {/*<Route path="/documents/create" element={<DocumentCreateWithContext />} />*/}
                        {/*<Route path="/documents/:id" element={<DocumentEditWithContext />} />*/}

                        <Route path=":document_id/contents_view/*" element={<ContentReadOnlyTree />}></Route>
                    </Resource>
                    <Resource name="paragraphs" list={SnapshotParagraphList} />
                </>
            )}

            {canEditParagraphs && (
                <>
                    <Resource name="edit/paragraphs" list={EditingParagraphList} />
                    <Resource
                        name="edit/documents"
                        list={EditingDocumentList}
                        // show={ContentReadOnlyTree}
                        options={{ label: '目次（最新修正案）' }}
                        icon={Edit}
                    >
                        <Route path=":document_id/edit/contents/*" element={<ContentEditPage />}></Route>
                        <Route path=":document_id/contents/*" element={<ContentTree />}></Route>
                    </Resource>
                    <Resource name="edits" list={EditList} show={EditDetail} options={{ label: '編集内容一覧' }} />
                    <Resource
                        name="merge_requests"
                        list={MergeRequestList}
                        edit={MergeRequestDetail}
                        options={{ label: '確認・承認依頼一覧' }}
                        icon={ListAlt}
                    />
                </>
            )}

            {canEditDocuments && (
                <Resource
                    name="admin/documents"
                    recordRepresentation="title"
                    list={DocumentList}
                    edit={DocumentEdit}
                    create={DocumentCreate}
                >
                    <Route path=":documentId/snapshots" element={<SnapshotList />}></Route>
                    <Route path=":documentId/snapshots/create" element={<SnapshotCreate />}></Route>
                    <Route path=":documentId/snapshots/:snapshotId" element={<SnapshotEdit />}></Route>
                    <Route path=":documentId/comparison-table" element={<ComparisonTableExportPage />}></Route>
                </Resource>
            )}

            {/*<Resource name="users" list={UserList} icon={UserIcon} recordRepresentation="name"/>*/}
            {canEditOrganizations && (
                <Resource
                    name="organizations"
                    list={OrganizationList}
                    edit={OrganizationEdit}
                    create={OrganizationCreate}
                    icon={Category}
                />
            )}
            {canShowUsers && <Resource name="users" list={UserList} icon={Accessibility} edit={UserEdit} />}
            {/*<Resource name="roles"/>*/}

            <Resource name="public_notice" list={PublicNoticeEdit} />

            {/* <CustomRoutes>はまとめて1箇所に記載する */}
            <CustomRoutes>
                <Route path="/usersettings" element={<Settings />} />
                {canEditParagraphs && <Route path="/diffs" element={<MergeRequestsCreate />} />}
            </CustomRoutes>
        </AdminUI>
    );
};
